@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Monoton&display=swap);
body {
  margin: 0;
  font-family: "Josefin Sans", sans-serif;
  background-color: #ffffff;
  background-image: linear-gradient(
      99deg,
      rgba(165, 165, 165, 0.03) 0%,
      rgba(165, 165, 165, 0.03) 8%,
      rgba(235, 235, 235, 0.03) 8%,
      rgba(235, 235, 235, 0.03) 9%,
      rgba(7, 7, 7, 0.03) 9%,
      rgba(7, 7, 7, 0.03) 14%,
      rgba(212, 212, 212, 0.03) 14%,
      rgba(212, 212, 212, 0.03) 17%,
      rgba(219, 219, 219, 0.03) 17%,
      rgba(219, 219, 219, 0.03) 95%,
      rgba(86, 86, 86, 0.03) 95%,
      rgba(86, 86, 86, 0.03) 100%
    ),
    linear-gradient(
      67.5deg,
      rgba(80, 80, 80, 0.03) 0%,
      rgba(80, 80, 80, 0.03) 11%,
      rgba(138, 138, 138, 0.03) 11%,
      rgba(138, 138, 138, 0.03) 17%,
      rgba(122, 122, 122, 0.03) 17%,
      rgba(122, 122, 122, 0.03) 24%,
      rgba(166, 166, 166, 0.03) 24%,
      rgba(166, 166, 166, 0.03) 27%,
      rgba(245, 245, 245, 0.03) 27%,
      rgba(245, 245, 245, 0.03) 89%,
      rgba(88, 88, 88, 0.03) 89%,
      rgba(88, 88, 88, 0.03) 100%
    ),
    linear-gradient(
      67.5deg,
      rgba(244, 244, 244, 0.03) 0%,
      rgba(244, 244, 244, 0.03) 4%,
      rgba(16, 16, 16, 0.03) 4%,
      rgba(16, 16, 16, 0.03) 10%,
      rgba(157, 157, 157, 0.03) 10%,
      rgba(157, 157, 157, 0.03) 20%,
      rgba(212, 212, 212, 0.03) 20%,
      rgba(212, 212, 212, 0.03) 83%,
      rgba(5, 5, 5, 0.03) 83%,
      rgba(5, 5, 5, 0.03) 84%,
      rgba(237, 237, 237, 0.03) 84%,
      rgba(237, 237, 237, 0.03) 100%
    ),
    linear-gradient(99deg, #ffffff, #ffffff);
}

.header {
  position: relative;
  background-color: #000;
  background: url(/static/media/Hero.0b73e82e.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  top: 80px;
  height: 900px;
  overflow: hidden;
}

.header-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgb(255, 255, 255);
}

@media only screen and (max-device-width: 1500px) {
  .header {
    top: 50px;
    height: 800px;
  }

  .header-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgb(255, 255, 255);
  }
}

.title {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: center;
  margin: 20px;
  z-index: 2;
}

.title h1 {
  color: #eeeeee;
  font-size: 80px;
  display: flex;
  align-items: baseline;
  position: relative;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  text-shadow: -2px 0 #0d1b1e, 0 -2px #0d1b1e, 2px 0 #0d1b1e, 0 2px #0d1b1e, 2px 2px #0d1b1e, -2px -2px #0d1b1e, -2px 2px #0d1b1e,
    2px -2px #0d1b1e, 5px 5px #5641ea;
}

h2 {
  font-size: 30px;
}

@media only screen and (max-device-width: 700px) {
  .header {
    top: 80px;
    height: 500px;
  }

  .title h1 {
    font-size: 50px;
  }

  .header-box h2 {
    margin: 0px;
    font-size: 22px;
  }
}

@media only screen and (max-device-width: 450px) {
  .header {
    top: 80px;
    height: 450px;
  }

  .title {
    margin: 10px;
    margin-bottom: 12px;
  }

  .title h1 {
    font-size: 40px;
    margin: 0;
  }
}

.contacts {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  justify-content: center;
  width: 600px;
  padding: 0;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .contacts {
    width: 220px;
  }
}

.lnk {
  color: #5641ea;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 28px;
}

.icon1 {
  color: #5641ea;
  padding-right: 10px;
  padding-left: 20px;
  font-size: 28px;
  cursor: pointer;
}

.icon2 {
  color: #5641ea;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 28px;
  cursor: pointer;
}

.icon3 {
  color: #5641ea;
  padding-right: 20px;
  padding-left: 10px;
  font-size: 28px;
  cursor: pointer;
}
.icon1:hover,
.icon2:hover,
.icon3:hover {
  color: #9588f7;
}

.line {
  height: 0px;
  width: 1px;
  border-bottom: 2.3px solid #5641ea;
  animation: increase 5s;
  animation-fill-mode: forwards;
}

@keyframes increase {
  100% {
    width: 500px;
  }
}

@media only screen and (max-device-width: 550px) {
  .line {
    display: none;
  }
}

.project-box {
  width: 1300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.mp {
  margin: 50px;
  margin-bottom: 40px;
  padding: 60px;
  font-size: 40px;
  text-decoration: underline;
  -webkit-text-decoration-color: #5641ea;
          text-decoration-color: #5641ea;
  text-decoration-thickness: 3.5px;
}

.project-cont {
  display: flex;
  justify-content: center;
}
.project-card {
  margin-bottom: 100px;
  width: 90%;
  height: 420px;
  align-self: center;
  display: flex;
  flex-direction: row-reverse;
}

.project-cont:nth-child(even) .project-card {
  flex-direction: row;
}

.project-image {
  width: 65%;
  border: 3px solid #001323;
  background-color: #5641ea;
  position: relative;
}

.project-data {
  flex-direction: column;
  width: 35%;
  padding: 20px;
}

.img-rumi {
  background: url(/static/media/Rumi.861be20b.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.img-info {
  background: url(/static/media/info.aecaaad6.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.img-photo-app {
  background: url(/static/media/photo-app.8d4d4b18.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.img-wildfire {
  background: url(/static/media/Wildfire.d1f97a05.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.img-netflix {
  background: url(/static/media/netflix.f3342b7b.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.img-crypto {
  background: url(/static/media/Crypto.86e1e1c9.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.img-portfolio {
  background: url(/static/media/Port.93b0c6ff.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.img-face {
  background: url(/static/media/Face.de205aec.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.img-robo {
  background: url(/static/media/Robo.9d12b931.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.img-hacks {
  background: url(/static/media/hacks.6db0ecf5.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.img-hacks2 {
  background: url(/static/media/hacks2.a0ccb9b3.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.img-rent {
  background: url(/static/media/Rent.560d4ef8.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.img-pixel {
  background: url(/static/media/Pixel.828779b4.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.img-hacks:hover,
.img-hacks2:hover,
.img-robo:hover,
.img-face:hover,
.img-portfolio:hover,
.img-crypto:hover,
.img-wildifre:hover,
.img-photo-app:hover,
.img-netflix:hover,
.img-rumi:hover,
.img-info:hover,
.img-wildfire:hover,
.img-rent:hover,
.img-pixel:hover {
  opacity: 1;
  /* cursor: pointer; */
}

.name {
  font-size: 35px;
  text-align: right;
  margin-bottom: -12px;
}
.project-cont:nth-child(even) .name {
  text-align: left;
}

.info {
  font-size: 20px;
  position: relative;
  padding: 23px;
  margin-left: -100px;
  text-align: right;
  border: 2.3px solid #001323;
  background-color: #fdfdfd;
  line-height: 25px;
  margin-bottom: 0px;
  z-index: 1;
}
.project-cont:nth-child(even) .info {
  position: static;
  margin-left: 0px;
  margin-right: -100px;
  text-align: left;
}

.project-skills {
  display: flex;
  justify-content: flex-end;
}

.project-cont:nth-child(even) .project-skills {
  display: flex;
  justify-content: flex-start;
  padding: 0;
}

.skill {
  padding-left: 10px;
  list-style-type: none;
  white-space: pre;
}

.project-cont:nth-child(even) .skill {
  padding-right: 10px;
  padding-left: 0px;
}

.project-links {
  display: flex;
  flex-direction: row;
  margin: 0;
  justify-content: flex-end;
}

.project-cont:nth-child(even) .project-links {
  justify-content: flex-start;
}

.demo {
  color: #5641ea;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 10px 20px 8px 20px;
  border: 2.3px solid #5641ea;
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
  font-family: "Josefin Sans", sans-serif;
  margin-left: 13px;
}

.project-cont:nth-child(even) .demo {
  margin-left: 0px;
  margin-right: 13px;
}

.demo:hover {
  color: #ffffff;
  background-color: #5641ea;
}

@media only screen and (max-device-width: 700px) {
  .project-image {
    display: none !important;
  }
  .project-data {
    flex-direction: column;
    width: 70%;
  }
  .project-card {
    margin-bottom: 10px;
    width: 100%;
    height: 40%;
    align-self: center;
  }
  .mp {
    padding: 0px;
    margin-bottom: 20px;
  }
  .info {
    margin-left: 0px;
    text-align: center;
    line-height: 23px;
  }
  .project-cont:nth-child(even) .info {
    margin-right: 0px;
    text-align: center;
    line-height: 23px;
  }

  .name {
    text-align: center;
  }
  .project-cont:nth-child(even) .name {
    text-align: center;
  }

  .project-skills {
    padding: 0;
    justify-content: center;
  }

  .project-cont:nth-child(even) .project-skills {
    align-items: center;
    padding: 0;
    justify-content: center;
  }

  .project-links {
    justify-content: center;
    align-items: center;
  }

  .project-cont:nth-child(even) .project-links {
    justify-content: center;
  }

  .demo {
    margin: 5px;
  }

  .project-cont:nth-child(even) .demo {
    margin: 5px;
  }
}

.bar {
  width: 100%;
  display: flex;
  align-items: center;
  color: #5641ea;
  position: fixed;
  z-index: 2;
  background-color: #fdfdfd;
  border-bottom: 1px solid #d1d1d1;
}

.logo {
  background: url(/static/media/boldd.ebc8c4a0.svg);
  background-size: 100% 100%;
  width: 200px;
  height: 53px;
  margin: 15px;
}

.nav {
  width: 100%;
  margin-right: 70px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
}

.btn {
  color: #5641ea;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 10px 20px 8px 20px;
  border: 2.3px solid #5641ea;
  font-size: 22px;
  background-color: transparent;
  cursor: pointer;
  font-family: "Josefin Sans", sans-serif;
}

.btn:hover {
  color: #ffffff;
  background-color: #5641ea;
}
.nav-ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-content: center;
  padding: 0;
}

nav li {
  color: #5641ea;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-size: 20px;
  cursor: pointer;
  padding-right: 30px;
}

nav li:hover {
  color: #5641ea;
}

@media only screen and (max-device-width: 450px) {
  .nav {
    margin-right: 40px;
  }
  .projects {
    display: none;
  }
}

.about-container {
  position: relative;
  z-index: -1;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
}

@media screen and (min-width: 1440px) {
  .aboutBG-1 {
    position: absolute;
    left: 50%;
    top: -80px;
    right: auto;
    bottom: auto;
    display: block;
    width: 70%;
    transform: translate(-50%, 0px);
    object-fit: fill;
  }
}

.aboutBG-1 {
  position: absolute;
  left: 50%;
  top: -72px;
  right: auto;
  z-index: -1;
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  transform: translate(-50%, 0px);
}

@media screen and (min-width: 1440px) {
  .aboutBG-2 {
    position: absolute;
    left: auto;
    top: -84px;
    right: -50px;
    bottom: auto;
    width: 33%;
  }
}

.aboutBG-2 {
  position: absolute;
  top: -73px;
  bottom: auto;
  width: 35%;
}

@media screen and (min-width: 1440px) {
  .aboutBG-3 {
    left: -54px;
    top: 84px;
    bottom: auto;
    width: 55%;
  }
}

.aboutBG-3 {
  position: absolute;
  top: 50px;
  bottom: auto;
  width: 55%;
}

.hl {
  color: #5641ea;
}

.about-box {
  position: relative;
  padding: 40px 100px 40px 100px;
  font-size: 25px;
  text-align: center;
  border: 3px solid #001323;
  background-color: #fdfdfd;
  line-height: 30px;
  width: 70%;
}

@media only screen and (max-device-width: 1500px) {
  .about-box {
    padding: 40px 90px 40px 90px;
  }
}

@media only screen and (max-device-width: 700px) {
  .about-container {
    margin-top: 140px;
  }

  .about-box {
    padding: 12px 10px 12px 10px;
    font-size: 20px;
    line-height: 23px;
    width: 80%;
  }

  .aboutBG-1 {
    display: none;
  }

  .aboutBG-2 {
    display: none;
  }

  .aboutBG-3 {
    display: none;
  }
}

.footer-box {
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-device-width: 700px) {
  .footer-box {
    margin-top: 50px;
    margin-bottom: 40px;
  }
}

a {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:focus {
  text-decoration: none;
}
a:hover,
a:active {
  text-decoration: none;
}

.top {
  color: #5641ea;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 10px 50px 8px 50px;
  border: 2.3px solid #5641ea;
  font-size: 22px;
  background-color: transparent;
  cursor: pointer;
  font-family: "Josefin Sans", sans-serif;
}

.top:hover {
  color: #5641ea;
  background-color: #d0ccfa;
}

.arrow {
  border: solid #5641ea;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 7px;
  margin-right: 10px;
  margin-bottom: -5px;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.center {
  display: flex;
  justify-content: center;
  color: #0c0808;
}
.loading {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  color: #5641ea;
}

.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
  font-size: 60px;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

