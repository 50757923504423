@import url("https://fonts.googleapis.com/css2?family=Monoton&display=swap");

.bar {
  width: 100%;
  display: flex;
  align-items: center;
  color: #5641ea;
  position: fixed;
  z-index: 2;
  background-color: #fdfdfd;
  border-bottom: 1px solid #d1d1d1;
}

.logo {
  background: url(/src/Images/boldd.svg);
  background-size: 100% 100%;
  width: 200px;
  height: 53px;
  margin: 15px;
}

.nav {
  width: 100%;
  margin-right: 70px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
}

.btn {
  color: #5641ea;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 10px 20px 8px 20px;
  border: 2.3px solid #5641ea;
  font-size: 22px;
  background-color: transparent;
  cursor: pointer;
  font-family: "Josefin Sans", sans-serif;
}

.btn:hover {
  color: #ffffff;
  background-color: #5641ea;
}
.nav-ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-content: center;
  padding: 0;
}

nav li {
  color: #5641ea;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-size: 20px;
  cursor: pointer;
  padding-right: 30px;
}

nav li:hover {
  color: #5641ea;
}

@media only screen and (max-device-width: 450px) {
  .nav {
    margin-right: 40px;
  }
  .projects {
    display: none;
  }
}
