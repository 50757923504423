.project-box {
  width: 1300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.mp {
  margin: 50px;
  margin-bottom: 40px;
  padding: 60px;
  font-size: 40px;
  text-decoration: underline;
  text-decoration-color: #5641ea;
  text-decoration-thickness: 3.5px;
}

.project-cont {
  display: flex;
  justify-content: center;
}
.project-card {
  margin-bottom: 100px;
  width: 90%;
  height: 420px;
  align-self: center;
  display: flex;
  flex-direction: row-reverse;
}

.project-cont:nth-child(even) .project-card {
  flex-direction: row;
}

.project-image {
  width: 65%;
  border: 3px solid #001323;
  background-color: #5641ea;
  position: relative;
}

.project-data {
  flex-direction: column;
  width: 35%;
  padding: 20px;
}

.img-rumi {
  background: url(/src/Images/Rumi.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.img-info {
  background: url(/src/Images/info.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.img-photo-app {
  background: url(/src/Images/photo-app.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.img-wildfire {
  background: url(/src/Images/Wildfire.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.img-netflix {
  background: url(/src/Images/netflix.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.img-crypto {
  background: url(/src/Images/Crypto.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.img-portfolio {
  background: url(/src/Images/Port.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.img-face {
  background: url(/src/Images/Face.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.img-robo {
  background: url(/src/Images/Robo.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.img-hacks {
  background: url(/src/Images/hacks.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.img-hacks2 {
  background: url(/src/Images/hacks2.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.img-rent {
  background: url(/src/Images/Rent.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.img-pixel {
  background: url(/src/Images/Pixel.png);
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.img-hacks:hover,
.img-hacks2:hover,
.img-robo:hover,
.img-face:hover,
.img-portfolio:hover,
.img-crypto:hover,
.img-wildifre:hover,
.img-photo-app:hover,
.img-netflix:hover,
.img-rumi:hover,
.img-info:hover,
.img-wildfire:hover,
.img-rent:hover,
.img-pixel:hover {
  opacity: 1;
  /* cursor: pointer; */
}

.name {
  font-size: 35px;
  text-align: right;
  margin-bottom: -12px;
}
.project-cont:nth-child(even) .name {
  text-align: left;
}

.info {
  font-size: 20px;
  position: relative;
  padding: 23px;
  margin-left: -100px;
  text-align: right;
  border: 2.3px solid #001323;
  background-color: #fdfdfd;
  line-height: 25px;
  margin-bottom: 0px;
  z-index: 1;
}
.project-cont:nth-child(even) .info {
  position: static;
  margin-left: 0px;
  margin-right: -100px;
  text-align: left;
}

.project-skills {
  display: flex;
  justify-content: flex-end;
}

.project-cont:nth-child(even) .project-skills {
  display: flex;
  justify-content: flex-start;
  padding: 0;
}

.skill {
  padding-left: 10px;
  list-style-type: none;
  white-space: pre;
}

.project-cont:nth-child(even) .skill {
  padding-right: 10px;
  padding-left: 0px;
}

.project-links {
  display: flex;
  flex-direction: row;
  margin: 0;
  justify-content: flex-end;
}

.project-cont:nth-child(even) .project-links {
  justify-content: flex-start;
}

.demo {
  color: #5641ea;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 10px 20px 8px 20px;
  border: 2.3px solid #5641ea;
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
  font-family: "Josefin Sans", sans-serif;
  margin-left: 13px;
}

.project-cont:nth-child(even) .demo {
  margin-left: 0px;
  margin-right: 13px;
}

.demo:hover {
  color: #ffffff;
  background-color: #5641ea;
}

@media only screen and (max-device-width: 700px) {
  .project-image {
    display: none !important;
  }
  .project-data {
    flex-direction: column;
    width: 70%;
  }
  .project-card {
    margin-bottom: 10px;
    width: 100%;
    height: 40%;
    align-self: center;
  }
  .mp {
    padding: 0px;
    margin-bottom: 20px;
  }
  .info {
    margin-left: 0px;
    text-align: center;
    line-height: 23px;
  }
  .project-cont:nth-child(even) .info {
    margin-right: 0px;
    text-align: center;
    line-height: 23px;
  }

  .name {
    text-align: center;
  }
  .project-cont:nth-child(even) .name {
    text-align: center;
  }

  .project-skills {
    padding: 0;
    justify-content: center;
  }

  .project-cont:nth-child(even) .project-skills {
    align-items: center;
    padding: 0;
    justify-content: center;
  }

  .project-links {
    justify-content: center;
    align-items: center;
  }

  .project-cont:nth-child(even) .project-links {
    justify-content: center;
  }

  .demo {
    margin: 5px;
  }

  .project-cont:nth-child(even) .demo {
    margin: 5px;
  }
}
